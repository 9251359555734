import { Button, colors, Tooltip } from "@cimpress/react-components";
import { Spinner } from "@cimpress/react-components/lib/shapes";
import {
    IconAlertTriangle,
    IconAdd,
    IconCheck,
} from "@cimpress-technology/react-streamline-icons/lib";
import React from "react";
import {
    addPdwDataset,
} from "@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common";
import { AuthContext } from "../../../../../context/authContext";
import { useAppDispatch, useAppSelector } from "../../../../../store/storeHooks";
import { parseUnknownErrorTypeToErrorMessage } from "@cimpress-technology/data-portal-core/lib/features/utils";
import { DatasetIdFormatType, translateDatasetId } from "../../../../../utils/dataset/datasetId";
import { fetchResourcesPdwRoles } from "@cimpress-technology/data-portal-core/lib/features/resources/common";

const COMPONENT_HEIGHT = "36px";

type Props = {
    dataProductId: string;
    datasetId: string;
    /** Holds the Data Product Id where the dataset is already in use (published).  */
    usedInThisDataProductId: string | null;
    datasetDomainId: string | null
    dataProductDomainId: string,
    isIncludedInDataProduct: boolean;
};

function getButton(props: Props, baseButton: JSX.Element): JSX.Element {
    function getButtonState(): { status: 'simpleAdd' | 'noDomainAdd' | 'diffDomainAdd' | 'notPossibleAdd' | 'alreadyAdded', isDisabled: boolean, reason: JSX.Element } {
        if (props.isIncludedInDataProduct) {
            return {
                status: 'alreadyAdded',
                isDisabled: true,
                reason: <span>
                    This PDW Dataset is a resource of your Data Product.
                </span>
            };
        }
        if (props.usedInThisDataProductId) {
            return {
                status: 'notPossibleAdd',
                isDisabled: true,
                reason: <span>
                    This PDW Dataset is used in another Data
                    Product &nbsp;
                    <a
                        href={`/dataProducts/${props.usedInThisDataProductId}?tab=Resources&subtab=resourcesPdwDataset`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {props.usedInThisDataProductId}
                    </a>
                    . You cannot add this PDW Dataset to your Data Product.
                </span>
            };
        }
        if (props.datasetDomainId != null && props.dataProductDomainId !== props.datasetDomainId) {
            return {
                status: 'diffDomainAdd',
                isDisabled: false,
                reason: <span>
                    This PDW Dataset is assigned to a different Domain than your Data Product Domain.
                    The PDW Dataset Domain will be updated in Data Discovery to match the Data Product Domain.
                </span>
            };
        }
        if (props.datasetDomainId == null) {
            return {
                status: 'noDomainAdd',
                isDisabled: false,
                reason: <span>
                    This PDW Dataset is not assigned to any Domain.
                    The PDW Dataset Domain will be updated in Data Discovery to match the Data Product Domain.
                </span>
            };
        }
        return {
            status: 'simpleAdd',
            isDisabled: false,
            reason: <></>
        };
    }
    const buttonState = getButtonState();
    const modifiedButton = React.cloneElement(baseButton, { disabled: buttonState.isDisabled });
    switch (buttonState.status) {
        case 'diffDomainAdd':
        case 'noDomainAdd':
        case 'notPossibleAdd':
            return (
                <Tooltip
                    direction="left"
                    contents={buttonState.reason}
                >
                    {modifiedButton}
                </Tooltip>
            );
        case 'alreadyAdded':
            return (
                <Tooltip
                    direction="left"
                    contents={buttonState.reason}
                >
                    <IconCheck
                        weight="fill"
                        style={{ color: colors.success.base }}
                    />
                </Tooltip>
            );
        case 'simpleAdd':
            return modifiedButton;
    }
}

export const AddPdwDatasetButton = (props: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { datasetsStatus, datasetsErrors } = useAppSelector(
        (state) => state.resourcesPdwDatasets
    );

    const { accessToken } = React.useContext(AuthContext);

    if (accessToken == null) return <div>Access Token required.</div>;

    const translatedDatasetId = translateDatasetId(props.datasetId, DatasetIdFormatType.PdwDatasetId);

    const AddButton = <Button
        variant="default"
        disabled={true}
        onClick={() =>
            dispatch(
                addPdwDataset({
                    accessToken,
                    dataProductId: props.dataProductId,
                    resourceId: translatedDatasetId
                })
            ).then((res) => {
                if (res.meta.requestStatus === "fulfilled")
                    dispatch(fetchResourcesPdwRoles({ accessToken, dataProductId: props.dataProductId }));
            })
        }
    >
        <IconAdd weight="fill" />
    </Button>;

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: COMPONENT_HEIGHT,
            }}
        >
            <>
                {!['loading', 'succeded'].includes(datasetsStatus[translatedDatasetId]?.add) && (
                    getButton(props, AddButton)
                )}
            </>

            {datasetsStatus[translatedDatasetId]?.add ===
                "failed" && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Tooltip
                        show={true}
                        contents={
                            <div style={{ wordWrap: "break-word" }}>
                                {parseUnknownErrorTypeToErrorMessage(
                                    datasetsErrors[
                                        translatedDatasetId
                                    ]
                                )}
                            </div>
                        }
                    >
                        <IconAlertTriangle
                            weight="fill"
                            style={{ color: colors.warning.base }}
                        />
                    </Tooltip>
                </div>
            )}
            {datasetsStatus[translatedDatasetId]?.add ===
                "loading" && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spinner size="medium" />
                </div>
            )}
        </div>
    );
};
