import { Button, colors, Modal, Tooltip } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAlertTriangle, IconBin } from '@cimpress-technology/react-streamline-icons/lib';
import React, { useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { InputPortComponent, InputPortType, InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../store/storeHooks';
import { removeSingleInputPort } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';
import { getPdwDatasetCollection } from '@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common';

type Props = {
    dataProductId: string;
    inputPortType: InputPortTypePath;
    inputPortBody: InputPortComponent<InputPortType>;
    disable: { disabled: true, message: string } | { disabled: false, message: null };
    style?: React.CSSProperties;
}

export const DeleteInputPortButton = (props: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { portDeleteStatus, portDeleteErrors } = useAppSelector(state => state.riverStreams);
    const [ errorDeleting, setErrorDeleting ] = useState('');
    const { accessToken } = React.useContext(AuthContext);
    const [ showConfirmationModal, setShowConfirmationModal ] = useState(false);

    const deleteInputPortOnClick = async () => {
        if (!accessToken) return;
        setErrorDeleting('');
        setShowConfirmationModal(false);
        try {
            dispatch(removeSingleInputPort({
                accessToken: accessToken,
                dataProductId: props.dataProductId,
                inputPortType: props.inputPortType,
                inputPortComponent: props.inputPortBody
            }))
            .then((res) => {
                if (res.meta.requestStatus === "fulfilled" && props.inputPortType === InputPortTypePath.RiverStreams) {
                    dispatch(getPdwDatasetCollection({ accessToken: accessToken, dataProductId: props.dataProductId }));
                }
            });
        } catch(error) {
            let errorMessage = 'There was an Error removing this input Port. Input port might have already been removed. Refresh and Try again or contact #data-domain-support';
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data.message || error.response?.statusText || error.message;
            }
            setErrorDeleting(errorMessage);
        }
    };

    const deleteStatus = portDeleteStatus[props.inputPortBody.resourceId];
    const deleteError = portDeleteErrors[props.inputPortBody.resourceId];

    return <div>
            <div style={{...props.style}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                    {
                        deleteStatus === "loading" && (
                            <Spinner size='medium' />
                        )
                    }
                    {
                        deleteStatus !== "loading" && props.disable.disabled === false && (
                            <>
                                <a style={{ cursor: "pointer" }} onClick={() => setShowConfirmationModal(true) }>
                                    <IconBin
                                        style={{color: colors.danger.base, height: '17px'}}
                                        weight='fill'
                                        />
                                </a>
                                {
                                    deleteStatus === "failed" && (
                                        <Tooltip show={true} contents={deleteError?.message || errorDeleting}>
                                            <IconAlertTriangle weight="fill" style={{color: colors.warning.base}} />
                                        </Tooltip>
                                    )
                                }
                            </>
                        )
                    }
                    {
                        deleteStatus !== "loading" && deleteStatus !== "failed" && props.disable.disabled === true && (
                            <Tooltip contents={props.disable.message}>
                                <IconBin
                                    style={{color: colors.alloy, height: '17px'}}
                                    weight='fill'
                                />
                            </Tooltip>
                        )
                    }
                </div>
            </div>

            <Modal
                closeButton={true}
                title="CONFIRMATION"
                closeOnOutsideClick={true}
                show={showConfirmationModal}
                onRequestHide={() => setShowConfirmationModal(false)} status='danger'
            >
                <div>
                    <p>Are you sure you want to delete <b>{props.inputPortBody.resourceId}</b> from the Data Product ?</p>
                </div>
                <br />
                <div className='pull-right'>
                    <Button onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
                    <Button
                        variant='primary'
                        style={{marginLeft: '5px'}}
                        onClick={deleteInputPortOnClick}
                    >
                        Confirm
                    </Button>
                </div>
            </Modal>
        </div>;
};
