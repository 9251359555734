import React, { ReactNode, useEffect, useState } from 'react';
import { TextField, Table, Drawer, Button, colors } from '@cimpress/react-components';
import { AuthContext } from '../../../../context/authContext';
import { InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAdd } from '@cimpress-technology/react-streamline-icons/lib';
import { DataPortalAPI } from '@cimpress-technology/data-portal-core';
import { AddInputPortButton } from '../../../shared/inputPorts/AddInputPortButton';
import { useRiverStreams } from '@cimpress-technology/data-portal-core/lib/hooks';
import { RiverStreamDetails } from '@cimpress-technology/data-portal-core/lib/interfaces/river';
import { useAppDispatch, useAppSelector } from '../../../../store/storeHooks';
import { setEditMode } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/riverStreamsSlice';
import {
    IconCheck,
} from "@cimpress-technology/react-streamline-icons/lib";


function filterRiverStreams(riverStreams: RiverStreamDetails[] | null, searchText: string): RiverStreamDetails[] {
    let filteredRiverStreams = riverStreams || [];
    if (searchText) {
        const lowercaseSearch = searchText.toLowerCase();
        filteredRiverStreams = filteredRiverStreams.filter(filteredRiverStream => filteredRiverStream.streamName.toLowerCase().includes(lowercaseSearch));
    }
    filteredRiverStreams.sort((a, b) => a.streamName.localeCompare(b.streamName));
    return filteredRiverStreams;
}

export const ManageRiverStream: React.FC<{
    dataProduct: DataPortalAPI.DataProduct,
}> = ({ dataProduct }) => {

    const dispatch = useAppDispatch();
    const { editMode, riverStreams, addStatus } = useAppSelector((state) => state.riverStreams);

    const setManageRiverStreams = (input: boolean) => {
        dispatch(setEditMode(input));
    };

    const { accessToken, profile } = React.useContext(AuthContext);
    const { streams, streamsError, loadingStreams } = useRiverStreams(accessToken, profile?.["https://claims.cimpress.io/canonical_id"]);
    const drawerSize = 0.55;    // Drawer size to display
    const [searchText, setSearchText] = useState('');
    const [filteredRiverStreams, setFilteredRiverStreams] = useState(filterRiverStreams(streams, searchText));

    useEffect(() => {
        // If user provides any search text, filter the river streams
        setFilteredRiverStreams(filterRiverStreams(streams, searchText));
    }, [streams, searchText, dataProduct]);

    const databaseColumns = [
        {
            Header: 'Add', accessor: '.',
            Cell: (row) => {
                return <div style={{ marginLeft: "-15px", display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}>
                    {
                        riverStreams?.some(stream => stream.resourceId === row.original.id)
                            ?   <IconCheck
                                    weight="fill"
                                    style={{ color: colors.success.base }}
                                />
                            :   <AddInputPortButton
                                    dataProductId={dataProduct.dataProductId}
                                    inputPortType={InputPortTypePath.RiverStreams}
                                    inputPortBody= {{
                                        resourceId: row.original.id,
                                        properties: {}
                                    }}
                                    tooltipDisplay={{ show: false, message: null }}
                                    button={
                                        <Button
                                            variant='default'
                                            style={{ height: "100%", width: "100%" }}
                                            disabled={false}
                                        >
                                            <IconAdd weight='fill'/>
                                        </Button>
                                    }
                                    style={{ height: 38, width: 65 }}
                                />
                        }
                    </div> as ReactNode;
            }
        },
        {
            Header: 'Stream Id', accessor: 'id', Cell: (row) => {
                return <a href={`https://console.river.cimpress.io/#/streams/${row.original.id}`} target='__blank' rel="noopener noreferrer">{row.original.id}</a> as ReactNode;
            }
        },
        { Header: 'Stream Name', accessor: 'streamName' },
        { Header: 'Namespace Id', accessor: 'namespaceId' },
        { Header: 'Stream Status', accessor: 'streamStatus' }
    ];

    return <>
        <Button className='pull-right' disabled={editMode} variant='primary' onClick={() => setManageRiverStreams(true)}>+/- Manage River Streams</Button>
        <Drawer
            show={editMode}
            onRequestHide={() => setManageRiverStreams(false)}
            size={drawerSize}
            closeOnClickOutside={true}
            footer={(
                <div className="col-xs-12">
                    <Button className='pull-right' onClick={() => setManageRiverStreams(false)}>Close</Button>
                </div>
            )}
        >
            { loadingStreams ?
                <>
                    <Spinner />
                    <p>Loading river streams you own</p>
                </>
            :
                <>
                    <div>
                        <div>
                            <div className='col-xs-7 row'>
                                <h2 style={{margin: 0, paddingLeft: 0}}>Manage <b>River Streams</b></h2>
                                <small style={{color: 'grey'}}>sourced from <a href="https://console.river.cimpress.io" target="_blank" rel="noreferrer">River</a></small>
                            </div>
                            <TextField
                                className='col-xs-5 pull-right'
                                value={searchText}
                                placeholder={'Search for river streams'}
                                helpText={`${filteredRiverStreams.length} streams found`}
                                onChange={(e) => {
                                    setSearchText(e.target.value || '');
                                }}
                                style={{margin: 0, padding: 0}}
                                disabled={loadingStreams || addStatus === 'loading' || !!streamsError}
                            />
                        </div>
                        <Table
                            hasHover={true}
                            data={filteredRiverStreams}
                            columns={databaseColumns}
                            noDataText={searchText != '' ? 'No river streams available matching the search' : 'No river streams available for this domain.'}
                        />
                    </div>
                </ >
            }
        </Drawer>
    </ >;
};
