import { useMemo, useState, useEffect } from "react";
import { AccountIdToDomains } from "../components/utils/customTypes";
import { useAppSelector } from "../../../../store/storeHooks";
import {
    DataProductListItem,
    OutputPortType,
} from "@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi";
import { getNumberOfOutputPortsByType } from "../components/utils/utils";
import { SliceState } from "@cimpress-technology/data-portal-core/lib/features/common";

const computeSortedFilteredList = (
    dataProductList: DataProductListItem[],
    inputValue: string,
    selectedAccountId: string | null,
    selectedDomainId: string | null,
    accountIdToDomains: AccountIdToDomains | null,
    onlyPublished: boolean | null,
    authorCanonicalId: string | null,
    analyticalLooker: boolean | null,
    dashboardCounts: Map<string, number>,
    datasetCounts: Map<string, number>
) => {
    let result = [...dataProductList];
    if (selectedAccountId) {
        if (accountIdToDomains == null) return result;
        const domainsInAccount = accountIdToDomains[selectedAccountId];
        if (domainsInAccount == null) return result;
        const domainIdsInAccount = accountIdToDomains[selectedAccountId].map(
            (o) => o.domain.domainId
        );
        result = result.filter((dp) =>
            domainIdsInAccount.includes(dp.domainId)
        );
    }
    if (selectedDomainId) {
        result = result.filter(
            (dp) =>
                dp.domainId === selectedDomainId || selectedDomainId === "all"
        );
    }
    if (onlyPublished) {
        result = result.filter((dp) => dp.published === onlyPublished);
    }
    if (authorCanonicalId) {
        result = result.filter(
            (dp) =>
                dp.createdBy?.toLowerCase() === authorCanonicalId.toLowerCase()
        );
    }
    if (analyticalLooker) {
        result = result.filter(
            (dp) => (dashboardCounts.get(dp.dataProductId) || 0) > 0
        );
    }
    if (inputValue && inputValue.trim().length > 0) {
        result = (result || []).filter(
            (o) =>
                o.dataProductName
                    .toUpperCase()
                    .includes(inputValue.toUpperCase()) ||
                o.summary.toUpperCase().includes(inputValue.toUpperCase())
        );
    }

    return (
        result.sort((a, b) => {
            if (
                (dashboardCounts.get(a.dataProductId) || 0) <
                (dashboardCounts.get(b.dataProductId) || 0)
            )
                return 1;
            if (
                (dashboardCounts.get(a.dataProductId) || 0) >
                (dashboardCounts.get(b.dataProductId) || 0)
            )
                return -1;
            if (
                (datasetCounts.get(a.dataProductId) || 0) <
                (datasetCounts.get(b.dataProductId) || 0)
            )
                return 1;
            if (
                (datasetCounts.get(a.dataProductId) || 0) >
                (datasetCounts.get(b.dataProductId) || 0)
            )
                return -1;
            if (a.updatedAt < b.updatedAt) return 1;
            if (a.updatedAt > b.updatedAt) return -1;
            return 0;
        }) || []
    );
};

interface GenericState {
    status: SliceState;
    dataProductsResultSet: DataProductListItem[] | null;
}

interface LoadingState extends GenericState {
    status: "loading";
    dataProductsResultSet: DataProductListItem[] | null;
}

interface SuccessState extends GenericState {
    status: "succeeded";
    dataProductsResultSet: DataProductListItem[];
}

interface IdleState extends GenericState {
    status: "idle";
    dataProductsResultSet: DataProductListItem[] | null;
}

type ReturnType = LoadingState | SuccessState | IdleState;

type Props = {
    searchInput: string;
    accountFilters: {
        selectedAccountId: string | null;
        accountIdToDomains: AccountIdToDomains | null;
    };
    domainFilters: {
        selectedDomainId: string | null;
    };
    onlyPublished: boolean | null;
    authorCanonicalId: string | null;
    analyticalFilters: {
        looker: boolean | null;
    };
};
/**
 * Debounced hook to compute the sorted and filtered list of data products.
 * @param props
 * @returns
 */
const useCalculateSortedFiltered = (props: Props): ReturnType => {
    const { dataProductList } = useAppSelector((state) => state.dataProducts);
    const [status, setStatus] = useState<SliceState>("idle");
    const [dataProductsResultSet, setDataProductsResultSet] = useState<
        DataProductListItem[] | null
    >(null);

    const dashboardCounts = useMemo(() => {
        return new Map(
            (dataProductList || []).map((o) => [
                o.dataProductId,
                getNumberOfOutputPortsByType(
                    o.outputPorts,
                    OutputPortType.LookerDashboards
                ),
            ])
        );
    }, [dataProductList]);

    const datasetCounts = useMemo(() => {
        return new Map(
            (dataProductList || []).map((o) => [
                o.dataProductId,
                getNumberOfOutputPortsByType(
                    o.outputPorts,
                    OutputPortType.PDWdataset
                ),
            ])
        );
    }, [dataProductList]);

    useEffect(() => {
        if (dataProductList != null && dataProductList.length > 0) {
            const newTimer = setTimeout(() => {
                setStatus("loading");
                setDataProductsResultSet(
                    computeSortedFilteredList(
                        dataProductList,
                        props.searchInput,
                        props.accountFilters.selectedAccountId,
                        props.domainFilters.selectedDomainId,
                        props.accountFilters.accountIdToDomains,
                        props.onlyPublished,
                        props.authorCanonicalId,
                        props.analyticalFilters.looker,
                        dashboardCounts,
                        datasetCounts
                    )
                );
                setStatus("succeeded");
            }, 300);
            return () => clearTimeout(newTimer);
        }
    }, [
        dataProductList,
        props.searchInput,
        props.accountFilters.selectedAccountId,
        props.domainFilters.selectedDomainId,
        props.accountFilters.accountIdToDomains,
        props.analyticalFilters.looker,
        props.onlyPublished,
        props.authorCanonicalId,
        dashboardCounts,
        datasetCounts,
    ]);

    switch (status) {
        case "loading":
            return {
                status: "loading",
                dataProductsResultSet: dataProductsResultSet || dataProductList,
            };
        case "succeeded":
            return {
                status: "succeeded",
                dataProductsResultSet:
                    dataProductsResultSet || dataProductList || [],
            };
        default:
        case "idle":
            return {
                status: "idle",
                dataProductsResultSet: dataProductsResultSet || dataProductList,
            };
    }
};

export default useCalculateSortedFiltered;
