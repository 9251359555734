import React, { useState } from 'react';
import { AuthContext } from '../../../../context/authContext';
import { Hooks } from "@cimpress-technology/data-portal-core";
import { Alert, Button, Modal, Select, TextField, Tooltip, Toggle } from '@cimpress/react-components';
import { renderError, renderLoading } from '../../../shared/Render';
import ErrorDetails from '@cimpress-technology/data-portal-core/lib/components/ErrorDetails';
import { AxiosError } from 'axios';
import { createDataProduct } from '@cimpress-technology/data-portal-core/lib/clients/dataPortalApi';
import { DataProductPayload } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { useHistory } from 'react-router-dom';

export const CreateNewDataProductModal: React.FC<{
    showModal: boolean
    onHideModal: () => void
}> = ({ showModal, onHideModal }) => {
    const { accessToken, profile } = React.useContext(AuthContext);
    const { domains, loadingDomains, domainsError, loadDomains } = Hooks.useDomains(accessToken, profile?.['https://claims.cimpress.io/account']);
    const [dataProductName, setDataProductName] = useState('');
    const [domainId, setDomainId] = useState('');
    const [summary, setSummary] = useState('');
    const [isResourceControlled, setIsResourceControlled] = useState(true);
    const [createError, setCreateError] = useState(null as AxiosError | null);
    const [creatingDataProduct, setCreatingDataProduct] = useState(false);
    const history = useHistory();

    if (!accessToken) {
        return null;
    }


    const domainOptions = domains?.filter(d => d.accountId === profile?.['https://claims.cimpress.io/account'])
        .map(a => ({ value: a.domainId, label: a.name })) || [];

    const hasMinimumDataProduct = () => {
        if (!dataProductName || !domainId || !summary) {
            return false;
        }
        return true;
    };

    const createNewDataProduct = async () => {
        setCreateError(null);
        setCreatingDataProduct(true);
        const minimumDataProductDetails = {
            dataProductName,
            domainId,
            summary,
            isResourceControlled
        } as DataProductPayload;
        try {
            const dataProduct = await createDataProduct(accessToken, minimumDataProductDetails);
            history.push(`/dataProducts/${dataProduct.dataProductId}`);
        } catch (error) {
            setCreateError(error as AxiosError);
        }
        setCreatingDataProduct(false);
    };


    return <Modal
        show={showModal}
        status='success'
        title="New Data Product"
        closeButton={true}
        onRequestHide={() => onHideModal()}
        footer={!loadingDomains && !domainsError ?
            <div>
                <Button onClick={() => onHideModal()} >Cancel</Button>
                <Tooltip show={!hasMinimumDataProduct() ? undefined : false} contents="Fill in the required fields to create" style={{ marginLeft: '5px' }}>
                    <Button disabled={!hasMinimumDataProduct()} onClick={createNewDataProduct} variant='primary' >
                        {creatingDataProduct ? <Spinner size='small' /> : 'Create'}
                    </Button>
                </Tooltip>

            </div> : undefined
        }
    >
        {loadingDomains ?
            renderLoading('Loading domains...', false, 'small')
            :
            domainsError ?
                <ErrorDetails title={'Error loading domains.'} error={domainsError} retryFunction={loadDomains} />
                :
                <div className='row'>
                    <div className='col-md-12'>
                        <Select
                            label="Domain"
                            required={true}
                            isClearable
                            value={domainOptions.find(s => s.value === domainId)}
                            onChange={(e) => setDomainId(e?.value || '')}
                            options={domainOptions}

                        />
                    </div>
                    <div className='col-md-12'>
                        <TextField
                            required={true}
                            value={dataProductName || ''}
                            label='Name'
                            onChange={(e) => setDataProductName(e.target.value)}
                        />
                    </div>

                    <TextField
                        required={true}
                        className="col-md-12"
                        label={`Summary of the data product`}
                        value={summary}
                        onChange={e => setSummary(e.target.value)}
                        inputStyle={{ resize: 'vertical' }}
                        type="textarea"
                    />

                    <div className="col-md-12" style={{marginBottom: '10px'}}>
                        <Toggle
                            size="sm"
                            on={isResourceControlled}
                            onClick={() => setIsResourceControlled(!isResourceControlled)}
                        />
                        <span style={{marginLeft: '10px'}}>Resource Controlled</span>
                    </div>

                    <div className='col-md-12'>
                        <Alert
                            status='info'
                            style={{cursor: 'default'}}
                            dismissible={false}
                            message={<span>
                                <ul>
                                    <li>You can add Data resources and ports after you create a Data product</li>
                                    <li>Data product will be created as unpublished and only be visible to Product owners.</li>
                                    <li> Enabling Resource Controlled flag converts your Data Product to a Resource Controlled Data Product.
                                        You authorize the CT Data Platform to manage and operate on your Data Product resources.
                                        Once accepted, the CT Data Platform may automatically initiate and execute operations without further user or client interaction,
                                        as directed by the data product admin(s), for processing, transformation, and management.
                                        <br></br>
                                        <b>CT Data Platform will ensure secure, compliant, and optimized handling of your resources throughout these processes.</b></li>
                                </ul>
                            </span>}
                        />
                    </div>
                    {createError ? <div className='col-md-12'>
                        {renderError(createError?.message, createError)}
                    </div>
                        : null
                    }
                </div>
        }
    </Modal >;
};
