import { Card, colors, Button, Alert } from '@cimpress/react-components';
import React from 'react';
import { DataPortalAPI } from "@cimpress-technology/data-portal-core";
import { renderError } from '../../../shared/Render';
import { ManageRiverStream } from '../../dataProductManagement/manageDataProduct/ManageRiverStreamDrawer';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconRedo } from '@cimpress-technology/react-streamline-icons/lib';
import { AuthContext } from '../../../../context/authContext';
import { useAppSelector, useAppDispatch } from '../../../../store/storeHooks';
import { getRiverStreams } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';
import { DeleteInputPortButton } from '../../../shared/inputPorts/DeleteInputPortButton';
import { InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/inputPorts';
import { MetadataProvider } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalAPI/outputPorts';
import { AddInputPortButton } from '../../../shared/inputPorts/AddInputPortButton';


export const RiverStreamDetails: React.FC<{ dataProduct: DataPortalAPI.DataProduct, hasDataProductManagePermission: boolean }> = ({ dataProduct, hasDataProductManagePermission }) => {

    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const { status, riverStreams } = riverStreamsState;

    return <div>
        <div className='row'>
            <div className='col-md-7'>
                <h3 style={{ margin: 0 }}>River streams</h3>
                <small>These are the river streams that feed the data product.</small>
            </div>
            <div className='col-md-5'>
                {
                    hasDataProductManagePermission === true && status !== 'loading' && riverStreams ?
                        <ManageRiverStream
                            dataProduct={dataProduct}
                        /> : hasDataProductManagePermission ? <Button className='pull-right' disabled={true}>Loading details...</Button> : null
                }
            </div>
        </div>
        <hr />
        <br />

        <RiverStreamDetailsSection dataProduct={{ dataProductId: dataProduct.dataProductId, published: dataProduct.published }} />
    </div>;
};

type Props = {
    dataProduct: {
        dataProductId: string;
        published: boolean;
    }
}
const RiverStreamDetailsSection = (props: Props): JSX.Element => {

    const dispatch = useAppDispatch();
    const riverStreamsState = useAppSelector(state => state.riverStreams);
    const { accessToken } = React.useContext(AuthContext);
    const { status, riverStreams, error, addStatus, portDeleteStatus } = riverStreamsState;

    if (error && !riverStreams?.length && accessToken) {
        return renderError(
            'Error loading river streams.',
            error as Error || new Error('River Streams not found'),
            () => dispatch(getRiverStreams({ accessToken: accessToken, dataProductId: props.dataProduct.dataProductId }))
        );
    }
    const riverStreamsToDisplay = riverStreams || [];
    const isAnyDeleteLoading = Object.values(portDeleteStatus).some(status => status === 'loading');

    return <div>
        {
            status === 'loading'
                ? <Alert status="info" message={<div style={{ display: 'flex' }}>Fetching River Streams<Spinner size='small' /></div>}></Alert>
                : null
        }
        {
            status === 'succeeded' && !riverStreamsToDisplay.length
                ? <Alert status="info" message="No river streams found."></Alert>
                : null
        }
        <div className='row'>
            <div className='col-lg-4 col-md-8 col-sm-12'>
                {
                    riverStreamsToDisplay.map(riverStream =>
                        <Card key={riverStream.resourceId} style={{ marginBottom: 16 }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h5 style={{ marginTop: 5, marginBottom: 5, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                    <a href={`https://console.river.cimpress.io/#/streams/${riverStream.resourceId}`} target='__blank' rel="noopener noreferrer">{riverStream._embedded.streamName}</a>
                                </h5>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DeleteInputPortButton
                                        dataProductId={props.dataProduct.dataProductId}
                                        inputPortType={InputPortTypePath.RiverStreams}
                                        disable={addStatus === "loading" || isAnyDeleteLoading ? { disabled: true, message: "Wait until the current operation is completed." } : { disabled: false, message: null }}
                                        inputPortBody= {{
                                            resourceId: riverStream.resourceId,
                                            properties: {
                                                metadataProviderId: MetadataProvider.DataDiscovery
                                            }
                                        }}
                                        style={{ height: 38, width: 65 }}
                                    />
                                    <AddInputPortButton
                                        dataProductId={props.dataProduct.dataProductId}
                                        inputPortType={InputPortTypePath.RiverStreams}
                                        inputPortBody= {{
                                            resourceId: riverStream.resourceId,
                                            properties: {}
                                        }}
                                        tooltipDisplay={{ show: undefined, message: "Synchronize River Stream. The River Stream and its PDW Resources are synchronized with the Data Product Resources." }}
                                        button={
                                            <Button
                                                variant='default'
                                                style={{ height: "100%", width: "100%", padding: "10px 14px" }}
                                                disabled={false}
                                            >
                                                <IconRedo />
                                            </Button>
                                        }
                                    />
                                </div>
                            </div>
                            <span style={{ color: colors.shale }}>{riverStream.resourceId}</span> <br /> <br />
                            <span style={{
                                color:
                                    riverStream._embedded.streamStatus === 'Available'
                                        ? colors.success.base
                                        : colors.danger.base
                            }}>{riverStream._embedded.streamStatus}</span>
                        </Card>
                    )
                }
            </div>
        </div>
    </div>;
};
