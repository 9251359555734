import { colors, Tooltip } from '@cimpress/react-components';
import { Spinner } from '@cimpress/react-components/lib/shapes';
import { IconAlertTriangle } from '@cimpress-technology/react-streamline-icons/lib';
import React, { useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { InputPortComponent, InputPortType, InputPortTypePath } from '@cimpress-technology/data-portal-core/lib/interfaces/dataPortalApi';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../../../store/storeHooks';
import { addSingleInputPort } from '@cimpress-technology/data-portal-core/lib/features/inputPorts/common';
import { getPdwDatasetCollection } from '@cimpress-technology/data-portal-core/lib/features/resourcesPdwDatasets/common';

type Props = {
    dataProductId: string;
    inputPortType: InputPortTypePath;
    inputPortBody: InputPortComponent<InputPortType>;
    tooltipDisplay: { show: undefined, message: string } | { show: false, message: null };
    button: JSX.Element;
    style?: React.CSSProperties;
}

export const AddInputPortButton = (props: Props) => {

    const dispatch = useAppDispatch();
    const { portAddStatus, portAddErrors } = useAppSelector(state => state.riverStreams);
    const defaultErrorMessage = 'There was an Error adding this input Port. Input port might have already been added. Refresh and Try again or contact #data-domain-support';
    const { accessToken } = React.useContext(AuthContext);
    const [ errorAdding, setErrorAdding ] = useState("");

    const addInputPortOnClick = async () => {
        if (!accessToken) return;
        setErrorAdding('');
        try {
            dispatch(addSingleInputPort({ accessToken: accessToken, dataProductId: props.dataProductId, inputPortType: props.inputPortType, inputPortComponent: props.inputPortBody }))
            .then((res) => {
                if (res.meta.requestStatus === "fulfilled" && props.inputPortType === InputPortTypePath.RiverStreams) {
                    dispatch(getPdwDatasetCollection({ accessToken: accessToken, dataProductId: props.dataProductId }));
                }
            });
        } catch(error) {
            let errorMessage = defaultErrorMessage;
            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data.message || error.response?.statusText || error.message;
            }
            setErrorAdding(errorMessage);
        }
    };

    const addStatus = portAddStatus[props.inputPortBody.resourceId];
    const addError = portAddErrors[props.inputPortBody.resourceId];

    return <div style={{...props.style}}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" , height: "100%", width: "100%" }}>
                    {
                        addStatus === "loading" && (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%" }}><Spinner size='medium' /></div>
                        )
                    }
                    {
                        addStatus !== "loading" && (
                            <>
                                {
                                    <Tooltip contents={<div style={{ wordWrap: "break-word" }}>{props.tooltipDisplay.message}</div>} show={props.tooltipDisplay.show}>
                                        {React.cloneElement(props.button, { onClick: addInputPortOnClick })}
                                    </Tooltip>
                                }
                                {
                                    addStatus === "failed" && (
                                        <div style={{ display: "flex", justifyContent: "center", marginLeft: "2px" }}>
                                            <Tooltip contents={<div style={{ wordWrap: "break-word" }}>{addError?.message || errorAdding}</div>}>
                                                <IconAlertTriangle weight="fill" style={{color: colors.warning.base}} />
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>;
};
